import { useState } from "react";

import { useTranslation } from "react-i18next";

import busd from "../../assets/Icons/busd.svg";
import usdt from "../../assets/Icons/usdt.svg";

function Defi() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
}

function Power() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="w-6 h-6"
    >
      <path d="M12 7.5a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z" />
      <path
        fillRule="evenodd"
        d="M1.5 4.875C1.5 3.839 2.34 3 3.375 3h17.25c1.035 0 1.875.84 1.875 1.875v9.75c0 1.036-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 011.5 14.625v-9.75zM8.25 9.75a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0zM18.75 9a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V9.75a.75.75 0 00-.75-.75h-.008zM4.5 9.75A.75.75 0 015.25 9h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H5.25a.75.75 0 01-.75-.75V9.75z"
        clipRule="evenodd"
      />
      <path d="M2.25 18a.75.75 0 000 1.5c5.4 0 10.63.722 15.6 2.075 1.19.324 2.4-.558 2.4-1.82V18.75a.75.75 0 00-.75-.75H2.25z" />
    </svg>
  );
}

function External() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-5 h-5"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25"
      />
    </svg>
  );
}

const Tickets = () => {
  const { t } = useTranslation();
  const [mousePos, setMousePos] = useState({});
  const handleMouseMove = (event) => {
    setMousePos({
      x: event.clientX - event.target.offsetLeft,
      y: event.clientY - event.target.offsetTop,
    });
  };
  return (
    // <div
    //   onMouseMove={handleMouseMove}
    //   className={`w-80-S mx-auto flex flex-col p-6 bg-th-bkg-1 border border-gray-800 rounded-lg`}
    // >
    //   <img src="/images/history/defi1.png" alt="borrowAndLend" />
    // </div>
    <></>
  );
};

const PriceFeed = () => {
  const { t } = useTranslation();
  const [mousePos, setMousePos] = useState({});
  const handleMouseMove = (event) => {
    setMousePos({
      x: event.clientX - event.target.offsetLeft,
      y: event.clientY - event.target.offsetTop,
    });
  };
  return (
    <div
      onMouseMove={handleMouseMove}
      className={`w-full md:w-49  bg-zinc-900  border border-stone-800 rounded-lg `}
    >
      <div className="w-full p-6 md:p-20 text-xl md:text-2xl leading-0 md:leading-8 tracking-custom font-semibold text-zinc-600 ws-4">
        <span className="text-white">
          {t("Evergreen Plant offers a Convertible Bond (NFT) ")}
        </span>
        {t(
          "allowing users to earn additional rewards by staking the NFT, along with exclusive offers."
        )}

        {/* <div className="flex flex-row mt-10 ai-c text-white underlineText max-w-fit">
          {t("Read about Zero Liquidation")}
          <span className="ml-2">
            <External />
          </span>
        </div> */}
      </div>
    </div>
  );
};

const Fallback = () => {
  const { t } = useTranslation();
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  const handleMouseMove = (event) => {
    setMousePos({
      x: event.clientX,
      y: event.clientY,
    });
  };
  return (
    <div
      onMouseMove={handleMouseMove}
      className="w-full md:w-49 mt-5 md:mt-0  bg-zinc-900  border border-stone-800 rounded-lg"
    >
      <div className="w-full p-6 md:p-20 text-xl md:text-2xl leading-0 md:leading-8 tracking-custom font-semibold text-zinc-600 ws-4">
        <span className="text-white">
          {t("Gyrowin Team is always striving")}
        </span>

        {t(
          " to build a robust and secure platform. We have added a fallback mechanism incase of any failure in oracle prices for the Gyrowin Defi."
        )}
        {/* <div className="mt-10 text-white underlineText max-w-fit flex flex-row ai-c">
          {t("Read about Fallback price")}
          <span className="ml-2">
            <External />
          </span>
        </div> */}
      </div>
    </div>
  );
};

const Liquidation = () => {
  const { t } = useTranslation();
  const [mousePos, setMousePos] = useState({});
  const handleMouseMove = (event) => {
    setMousePos({
      x: event.clientX - event.target.offsetLeft,
      y: event.clientY - event.target.offsetTop,
    });
  };
  return (
    <div
      onMouseMove={handleMouseMove}
      className={`w-full md:w-4/5 md:mx-auto flex flex-col md:flex-row bg-zinc-900  border border-stone-800 rounded-lg relative`}
    >
      <img
        className="absolute w-44 h-44 -right-10 -top-32 md:w-60 md:h-60 md:-right-16 md:-top-36"
        src="/images/feature/moneyplant.png"
        alt="moneyplant"
      />
      <span className="absolute h-10 w-10 right-10 md:right-10 -top-28 bg-green-400 blur-2xl"></span>

      <div className="w-full md:w-2/4 p-6 md:p-20 text-xl md:text-2xl leading-0 md:leading-8 tracking-custom font-semibold text-zinc-600 ws-4">
        <span className="text-white">{t("Earn rewards")}</span>
        {t(
          " by depositing Gyrowin tokens in the Money Plant vault. Users can choose to defer rewards for a certain period, which lead to even larger rewards. Grow your luck and wealth by investing in the Money Plant."
        )}

        <a
          href="https://docs.gyro.win/utility/money-plant"
          target="_blank"
          rel="noreferrer"
        >
          <div className="flex flex-row mt-10 ai-c text-white underlineText max-w-fit">
            {t("Read about Money plant vault")}
            <span className="ml-2">
              <External />
            </span>
          </div>
        </a>
      </div>

      <div className="w-full md:w-2/4  flex flex-col items-center">
        <div className="h-full w-full pt-14 pb-8 md:py-15 pr-5 flex flex-col ai-c">
          <div className="flex flex-row items-end">
            <div className="flex flex-col jc-c ai-c">
              {/* <div className="relative group">
                <div className=" absolute blur opacity-0 group-hover:opacity-100 -inset-0.5 bg-gradient-to-r from-green-600 to-green-600"></div>
                <div className=" flex flex-row  bg-zinc-900  max-w-fit shadow-sm hover:shadow-md hover:shadow-green-400 px-6 py-3 items-center  border border-gray-600 rounded-lg">
                  <div className="mr-2">
                    {" "}
                    <img
                      className="h-7 w-7"
                      src="/gyrowin.png"
                      alt="gyrowin"
                    ></img>
                  </div>
                  <div className="flex flex-col leading-none text-white">
                    <div className="">{t("$GYROWIN")}</div>
                    <div className="font-normal text-xs">{t("Mint Token")}</div>
                  </div>
                </div>
              </div> */}

              {/* <div className="h-10 w-1 bg-gradient-to-b from-neutral-900 via-green-500  to-green-500"></div> */}

              <div className="relative group">
                <div className=" absolute blur opacity-0 group-hover:opacity-100 -inset-0.5 bg-gradient-to-r from-green-600 to-green-600"></div>
                <div className=" flex flex-row  bg-zinc-900  max-w-fit shadow-sm hover:shadow-md hover:shadow-green-400 px-6 py-3 items-center  border border-gray-600 rounded-lg">
                  <div className="mr-2">
                    {" "}
                    <img
                      className="h-7 w-7"
                      src="/gyrowin.png"
                      alt="gyrowin"
                    ></img>
                  </div>
                  <div className="flex flex-col leading-none text-white">
                    <div className="">{t("$GYROWIN")}</div>
                    <div className="font-normal text-xs">{t("Token Tax")}</div>
                  </div>
                </div>
              </div>

              <div className="h-10 w-1 bg-gradient-to-b from-neutral-900 via-green-500  to-green-500"></div>

              <div className="w-full relative group">
                <div className=" absolute blur opacity-40 group-hover:opacity-100 -inset-0.5 bg-gradient-to-r from-green-600 to-teal-500"></div>
                <div className=" flex flex-row  bg-zinc-900 w-full jc-c shadow-sm hover:shadow-md hover:shadow-green-400 px-6 py-3 items-center  border border-gray-600 rounded-lg">
                  <div className="mr-2">
                    <span className="text-green-400">
                      <Power />
                    </span>
                  </div>
                  <div className="flex flex-col leading-none text-white">
                    <div className="">{t("MONEY PLANT")}</div>
                    <div className="font-normal text-xs">{t("Rewards")}</div>
                  </div>
                </div>
              </div>

              <div className="h-10 w-1 bg-gradient-to-t from-neutral-900 via-green-500  to-green-500"></div>

              <div className="max-w-fit mx-auto relative group ">
                <div className=" absolute blur opacity-0  group-hover:opacity-100 -inset-0.5 bg-gradient-to-r from-green-600 to-green-600 "></div>
                <div className=" flex flex-row  bg-zinc-900 jc-c shadow-sm hover:shadow-md hover:shadow-green-400 px-6 py-3 items-center  border border-gray-600 rounded-lg ">
                  <div className="mr-2 flex flex-row items-center">
                    {" "}
                    <img className="h-7 w-7 mr-1" src={usdt} alt="usdt"></img>
                  </div>
                  <div className="flex flex-col leading-none text-white">
                    <div className="">{t("$USDT")}</div>
                    <div className="font-normal text-xs">
                      {t("Lent Earnings")}
                    </div>
                  </div>
                </div>
              </div>

              <div className="h-10 w-1 bg-gradient-to-t from-neutral-900 via-green-500  to-green-500 visible md:hidden"></div>

              <div className=" md:hidden max-w-fit mx-auto relative group ">
                <div className=" absolute blur opacity-0  group-hover:opacity-100 -inset-0.5 bg-gradient-to-r from-green-600 to-green-600 "></div>
                <div className=" flex flex-row  bg-zinc-900 jc-c shadow-sm hover:shadow-md hover:shadow-green-400 px-6 py-3 items-center  border border-gray-600 rounded-lg ">
                  <div className="mr-2 flex flex-row items-center">
                    {" "}
                    <img className="h-7 w-7 mr-1" src={busd} alt="token"></img>
                  </div>
                  <div className="flex flex-col leading-none text-white">
                    <div className="">{t("$Tokens")}</div>
                    <div className="font-normal text-xs">
                      {t("Lottery Pool")}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="md:flex md:flex-col items-left hidden md:visible">
              <div className="flex flex-row ai-c">
                <div className="h-1 w-10 bg-gradient-to-r from-green-500 via-green-500 to-neutral-900"></div>

                <div className="relative group">
                  <div className=" absolute blur opacity-0 group-hover:opacity-100 -inset-0.5 bg-gradient-to-r from-green-600 to-green-600"></div>
                  <div className=" flex flex-row  bg-zinc-900  max-w-fit shadow-sm hover:shadow-md hover:shadow-green-400 px-6 py-3 items-center  border border-gray-600 rounded-lg">
                    <div className="mr-2 text-purple-500 text-4xl">
                      <img className="h-6 w-6 mr-1" src={busd} alt="token"></img>
                    </div>
                    <div className="flex flex-col leading-none text-white">
                      <div className="">{t("$Tokens")}</div>
                      <div className="font-normal text-xs">
                        {t("Lottery Pool")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="h-10 w-1"></div>

              {/* for the space */}
              <div className="flex flex-row ai-c opacity-0">
                <div className="h-1 w-10 bg-gradient-to-r from-green-500 via-green-500 to-neutral-900"></div>

                <div className="relative group">
                  <div className=" absolute blur opacity-0 group-hover:opacity-100 -inset-0.5 bg-gradient-to-r from-green-600 to-green-600"></div>
                  <div className=" flex flex-row  bg-zinc-900  max-w-fit shadow-sm hover:shadow-md hover:shadow-green-400 px-6 py-3 items-center  border border-gray-600 rounded-lg">
                    <div className="mr-2 text-purple-500 text-4xl">
                      <img className="h-6 w-6 mr-1" src={busd} alt="busd"></img>
                    </div>
                    <div className="flex flex-col leading-none text-white">
                      <div className="">{t("$BUSD ")}</div>
                      <div className="font-normal text-xs">{t("Earnings")}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Feature = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="w-100  bg-neutral-900 flex flex-col px-4 md:px-0 break-keep">
        <div className="w-80-S mx-auto w-100-XS d-f flex-row jc-c">
          <div className="flex flex-col mr-2 md:ml-8 md:mr-8 ai-c ">
            <div className="h-20 md:h-44 w-1 rounded-md bg-gradient-to-b from-neutral-900 to-green-500"></div>
            <div className="text-white flex ai-c mb-6 mt-6">
              <Defi />
              <span className="absolute z-1 h-5 w-6 l-44 b-10 r-0 bg-green-400 blur-lg"></span>
            </div>
            <div className="h-full w-1 rounded-md bg-gradient-to-b from-green-500 to-neutral-900"></div>
          </div>
          <div className="flex flex-row">
            <div className=" mx-auto flex flex-col pt-16 pb-20 md:py-32">
              <div className="w-80-S  text-2xl text-white mb-4 font-semibold flex flex-row ai-c">
                {t("Decentralized Finance")}
              </div>
              <div className="w-full md:w-4/5 text-2xl md:text-5xl leading-0 md:leading-12 tracking-custom font-semibold text-white ws-4  mb-0 md:mb-8 pr-5 md:pr-0">
                <span className="text-green-400 ">
                  {t("Gyrowin Defi protocol is the best place")}
                </span>
                {t(" where you can earn higher yield for your deposits.")}
              </div>
              {/* <div className="w-50-S  text-3xl leading-8 tracking-custom font-semibold text-zinc-600 ws-4 mb-24">
              <span className="text-white ">{t("Delegate votes  ")}</span>
              {t(
                "for new lottery game every week from various categories, alongside with other governance proposols to improve Gyrowin protocol."
              )}
              
            </div> */}
            </div>
          </div>
        </div>
        <Tickets />
        <div className="w-4/5 md:mx-auto w-100-XS d-f flex-row ">
          <div className="flex flex-col mr-2 md:mx-8 ai-c  ">
            <div className="h-full w-1 rounded-md bg-gradient-to-b from-neutral-900 via-green-500 to-neutral-900"></div>
            {/* <div className="text-white flex ai-c mb-6 mt-6"> */}
            <div className="w-6"></div>
            {/* </div> */}
          </div>

          {/* <div className="flex flex-col">
            <div className="flex flex-row pt-44 pb-20 md:py-20 h-full ">
              <div className="w-full md:w-2/4 text-xl md:text-3xl leading-0 md:leading-8 tracking-custom font-semibold text-zinc-600 ws-4">
                <span className="text-white ">
                  {t("Gyrowin Defi platform")}
                </span>
                {t(
                  " supports Time deposit of stablecoins through Evergreen plant alongside staking."
                )}
                <div className="mt-4 md:mt-10 text-lg md:text-3xl text-white underlineText max-w-fit flex flex-row ai-c">
                  {t("Invest with Gyrowin Defi")}
                  <span className="ml-2">
                    <External />
                  </span>
                </div>
              </div>

              <div className="md:w-3/5 h-full relative">
                <div className="absolute h-40 w-48 md:h-101 md:w-101 -top-48 md:-top-24 -right-5 md:-right-5 bg-th-bkg-2 border border-gray-800 rounded-md border-box">
                  <img src="/images/history/defi2.png" alt="defi-box" />
                </div>
              </div>
            </div>

            <div className="flex flex-row pb-24 md:py-36">
              <div className=" flex flex-col">
                <div className="w-full text-white">
                  {" "}
                  <div className="text-green-400 font-normal">
                    {t("Did you know?")}
                  </div>
                  <div className="text-green-400 text-4xl md:text-6xl">
                    {" "}
                    <span>{t("15% ")}</span>
                    {t("of the Lottery")}
                  </div>
                  <div className="w-full md:w-4/5 text-xl md:text-2xl mt-3">
                    {t(
                      " prize pool is rewarded to the Plant dad and mom every week in $BUSD."
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <Liquidation />
        <div className="w-full md:w-4/5 mx-auto flex flex-col md:flex-row md:px-0 jc-sb mt-9">
          <PriceFeed />
          <Fallback />
        </div>

        
      </div>
    </>
  );
};

export default Feature;
