import Intro from '../components/Intro/intro';
import Header from '../components/Header/header';
import Jackpot from '../components/Jackpot/jackpot';
import Feature from '../components/Features/features';
import History from '../components/History/history';
import Info from '../components/Info/info';
import Footer from '../components/Footer/footer';
import Roadmap from '../components/Roadmap/roadmap';
import Tokenomics from '../components/Tokenomics/tokenomics';
import Team from '../components/Team/team';
import Advisors from '../components/Advisors/advisors';
import Technical from '../components/technical';
import Referral from '../components/Referral';
import { Analytics } from '@vercel/analytics/react';
import Notification from '../components/Notification';

import Pricing from './pricing';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Api from './api';
import PredictionMarket from '../components/PredictionMarket';
import BorrowPlant from '../components/Features/borrowPlant';


function Main() {
  return (
    <>
      <Intro />
      {/* <Tokenomics /> */}
      {/* <History /> */}
      <Feature />
      <BorrowPlant/>
      <Jackpot />
      <PredictionMarket/>
      <Footer></Footer>
    </>
  )
}

function App() {
  return (
    // <Router>
    <div className="App overflow-hidden bg-zinc-900 break-keep">

      <BrowserRouter>

        <Analytics mode='production' />

        {/* <Header/> */}
        {/* <Notification /> */}

        {/* <Roadmap /> */}
        {/* <Team/>
        <Advisors/> */}
        {/* <Referral/> */}


        <Routes>
          <Route exact path="/" element={<Main />} />
          <Route exact path="/pricing" element={<Pricing />} />
          <Route exact path="/products/api" element={<Api/>} />
        </Routes>

      </BrowserRouter>


    </div>
    // </Router>
  );
}

export default App;
